@import 'theme/colors';
@import 'theme/variables';
@import 'components/dropdowns/dropdowns';
@import 'theme/spacing';
@import 'theme/breakpoints';
@import 'theme/main';

%boxHeading {
  color: $colorWhite;
  padding: 0.9em 1em;
  background-color: $color-student-neutral-dark-grey;
  margin: 0;
}

%autoWideImage {
  width: 100%;
  height: auto;
}

%cardShadow,
%btnShadow {
  box-shadow: 0 2px 4px $color-ui-shadow-random-black2;
}

%lightShadow {
  box-shadow: 0 0 2px 2px $color-ui-shadow-random-gray3;
}

%insetTopShadow {
  box-shadow: inset 0 2px 4px $color-ui-shadow-random-black3;
}

%nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

%dropdownArrowFix {
  &:before {
    left: 3.2rem;
    top: 0;

    border-color: transparent transparent $color-ui-background-default $color-ui-background-default;
    box-shadow: -2px 2px 2px 0 $color-ui-shadow;

    transform-origin: 0 0;
    transform: rotate(135deg);
  }
}

%buttonWithDashedBorder {
  color: $color-student-primary-teal;
  font-size: 1rem;
  background-color: $color-ui-background-default;
  border: 1px dashed $color-student-neutral-light-grey;
  width: 100%;
  padding: $spc-l;
  display: flex;
  align-items: center;
  text-align: left;
}

%tabSectionAddButton {
  @extend %btnShadow;
  margin-left: auto;
  min-width: 56px;
  width: 56px;
  height: 56px;
  color: $color-ui-background-default;
  background-color: $color-student-accent-fuschia;
  border-radius: 50%;
  animation: rotateBackwardsSign 0.2s ease-in-out;
  animation-fill-mode: backwards;

  &.adding {
    animation: rotateSign 0.2s ease-in-out;
    animation-fill-mode: forwards;
  }
}

%responsiveInlining {
  margin-top: $spc-l;
  display: flex;
  flex-direction: row;

  // it's usually a div or a button
  // we could be more specific with
  & > div,
  & > button {
    margin-right: $spc-m;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: stretch;

    & > div,
    & > button {
      margin-bottom: $spc-m;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
