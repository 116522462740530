@import 'theme/main';
@import 'theme/spacing';

:global(#root) {
  height: 100%;
}

:global(.inmplayer-general) {
  background-color: $color-navy-500;
}

.app {
  height: 100%;
  max-width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.authorized {
  flex: 1;
  // https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 auto;
  }
}
